import React from 'react'
import { loadStripe } from '@stripe/stripe-js'
import Layout from '../../components/layout'
import { isDev } from '../../lib/env'

const stripePublicKey = isDev() ? 'pk_test_OcKVcfCYlekrHFhoQH5eJ3A9' : 'pk_live_bXT3CBGXTpXZALvV2KFI2d2g'
const stripePromise = loadStripe(stripePublicKey)
const priceID = isDev() ? 'price_1HNDCPAok6MNpDKhvblR6Fr8' : 'price_1HNCxsAok6MNpDKhv8UtBins'
const hostName = isDev() ? 'http://localhost:8000' : 'https://aviaryan.com'

// https://stripe.com/docs/payments/checkout/client

function App() {
	const handleClick = async () => {
		// When the customer clicks on the button, redirect them to Checkout.
		const stripe = await stripePromise
		await stripe.redirectToCheckout({
			lineItems: [
				{
					price: priceID, // Replace with the ID of your price
					quantity: 1,
				},
			],
			mode: 'payment',
			successUrl: `${hostName}/products/payment-success`,
			cancelUrl: `${hostName}/products/dmc`,
		})
		// If `redirectToCheckout` fails due to a browser or network
		// error, display the localized error message to your customer
		// using `error.message`.
	}

	return (
		<Layout title="Developer Marketing Course Pre-Order">
			<div className="article">
				<h1>Developer Marketing Course Pre-Order</h1>
				<div className="post-content">
					<div>You can pre-order my course from here.</div>
					<button role="link" onClick={handleClick}>
						Checkout
					</button>
				</div>
			</div>
		</Layout>
	)
}

export default App
